import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import ConfiguratorPageOrderApproved from 'components/ConfiguratorPageOrderApproved';

const ConfiguratorOrderApproved = ({ location }) => {
  const price = _get(location, 'state.price', null);
  const amountOfPanels = _get(location, 'state.amountOfPanels', null);
  const paymentData = _get(location, 'state.paymentData', null);
  const selectedPanelType = _get(location, 'state.selectedPanelType', null);

  return (
    <ConfiguratorPageOrderApproved
      price={price}
      amountOfPanels={amountOfPanels}
      paymentData={paymentData}
      selectedPanelType={selectedPanelType}
    />
  );
};

ConfiguratorOrderApproved.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default ConfiguratorOrderApproved;
