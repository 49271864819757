import _find from 'lodash/find';

import getDeliveryPrice from 'utils/getDeliveryPrice';
import config from 'config';

const converseGtag = (price, amountOfPanels, paymentData, selectedPanelType) => {
  if (typeof window === 'undefined') {
    return;
  }

  const selectedPanel = _find(config.panelTypes, { id: selectedPanelType });

  window.dataLayer.push({ ecommerce: null }); // clear

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transactionId: paymentData.orderID,
      transactionAffiliation: 'Ehomuteboards',
      transactionTotal: price,
      transactionTax: parseFloat(price - (price / 1.23)).toFixed(2),
      transactionShipping: getDeliveryPrice(amountOfPanels),
      currency: 'EUR',
      transactionProducts: [{
        name: selectedPanel.name,
        sku: selectedPanel.id,
        price: price / amountOfPanels,
        item_brand: 'Ehomuteboards',
        category: selectedPanel.groupId,
        item_variant: selectedPanel.name,
        quantity: amountOfPanels,
      }],
    },
  });
};

export default converseGtag;
