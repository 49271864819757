import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import converseGtag from 'utils/converseGtag';

import './ConfiguratorPageOrderApproved.scss';

const ConfiguratorPageOrderApproved = ({
  price, amountOfPanels, paymentData, selectedPanelType,
}) => {
  useEffect(() => {
    converseGtag(price, amountOfPanels, paymentData, selectedPanelType);
  });

  return (
    <div className="configurator-page-order-approved">
      <h1>
        <FormattedMessage id="configuratorPageOrderApproved" />
      </h1>
    </div>
  );
};

ConfiguratorPageOrderApproved.propTypes = {
  amountOfPanels: PropTypes.number.isRequired,
  paymentData: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
  price: PropTypes.number.isRequired,
  selectedPanelType: PropTypes.string.isRequired,
};

export default ConfiguratorPageOrderApproved;
